<template>
  <div class="app">
    <div class="backGroundImg10086">
      <span style="margin-top: 20px;display: block">此网页由 wap.10086.cn 提供</span>
    </div>
    <router-view />
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {getAN, getUrlParamsObj, tansParams} from "@/utils";
import cache from "@/utils/cache";
import {getWxkflj, quxiaoqiangzhitingji} from "@/api/iot";
import VConsole from 'vconsole';
export default {
  data(){
    return {
      ts: 0,
      clickCount: 0,
      baseArr: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9']
    }
  },
  computed:{
    ...mapState(['jumpRequest','jumpResponse','logArr'])
  },
  async created(){
    // const res = await axios.get("https://restapi.amap.com/v3/ip?output=jsonp&key=d9103273914f41f08d7f1e16acc1b681");
    // if (res.data && res.data.province && res.data.city) {
    //   const resProvince = res.data.province;
    //   const resCity = res.data.city;
    //   if (typeof resProvince === 'string') {
    //     province = resProvince;
    //   }
    //   if (typeof resCity === 'string') {
    //     city = resCity;
    //   }
    // }

    let url1 = 'https://apis.map.qq.com/ws/location/v1/ip';
    const res = await this.$jsonp(url1, {
      key: 'UITBZ-WPQWN-GREFS-SSJHF-EEWG2-N3FCF',
      output: 'jsonp',
    })
    // {"status":0,"message":"Success","request_id":"88b2c4dd2bd34cad9bc0fff79890c4c6","result":{"ip":"119.103.139.92","location":{"lat":30.59276,"lng":114.30525},"ad_info":{"nation":"中国","province":"湖北省","city":"武汉市","district":"","adcode":420100,"nation_code":156}}}
    if (res.status === 0) {
      const {province,city} = res.result && res.result.ad_info || {};
      cache.local.setJSON('location',{province,city})
    }

    // saveCardInfo({});
    let urlParamsObj = getUrlParamsObj();
    const debug = urlParamsObj.debug;
    if (debug) {
      const vConsole = new VConsole();
      // 接下来即可照常使用 `console` 等方法
      console.log('Hello world');
      // 结束调试后，可移除掉
      // vConsole.destroy();
    }
    let a = urlParamsObj.a;
    const n = urlParamsObj.n;
    const type = urlParamsObj.type || ''; // ka、shebei
    const app = urlParamsObj.app;
    if (app) {
      cache.local.set('app','1');
      this.$store.commit('setOpenId1', "aaa");
    }
    // console.log("App.vue created",urlParamsObj)
    // this.$toast(a+','+n)
    let a3 = 2;
    if (a == '3') {
      a3 = 1;
      a = 1;
    }
    // 2024年8月29日11:11 yd 技术部-内部 2、 新增a=3链接和a=1一样，只不过比a=1多了active页面的联系客服； （自定义这个联系客服是否开启）
    cache.session.set("a3", a3);
    if (type) {
      // 2024年8月29日12:02 yd 技术部-内部
      // 1、a=1和2和3页面，均增加type类型，当从该type类型进入的，能识别到卡类型，就按照识别的来，不能识别的按照type值来。如果既没有识别的卡类型，也没有type值则跳转到logn页面输入卡号。
      // 2、type值需要传递到logn页面 和 active页面的“联系客服”，分别指向https://a.10086-wj.cn/qywxkf?type=shebei 和 https://a.10086-wj.cn/qywxkf?type=ka
      // 3、如果没有识别出卡号，则套餐图片和支付组都用默认套餐组的。
      cache.session.set("type", type);
    }
    if (a) {
      cache.session.set("a", a);
    }
    if (n) {
      cache.session.set("n", n);
      cache.local.set("n", n);
    }
    const cardNo = n || this.$store.state.cardNo;
    // 2024年1月2日15:35 yd 技术部-内部 4、当进入任意我们10086页面的时候（任意包含：首页、收藏页、客服联系诊断页面、订购套餐、套餐详情、更多页面等所有页面），检查到用户是“强制停机为”是“的时候，都改为否。
    if (cardNo) {
     // quxiaoqiangzhitingji(n,this.$store.state.cardNo).then(res=>{})
    }

    try {
      getWxkflj().then(res => {
        // res {wxkfljA:'http://xxxx',wxkfljB:'http://xxxx'}
        cache.local.setJSON('wxkflj', res);
      })
    } catch (e) {
    }
  },
  methods:{
  }
}
</script>

<style>
.backGroundImg10086{
  width:100vw;
  position:fixed;
  z-index:-100;
  background-color: #EFEFEF;
  color: #c4c2c2;
  font-size: 12px;
  text-align: center;
  top: 0;
}
html, body{
  height: 100%;
  width: 100%;
}
.app{
  padding: 0;
  margin: 0;
  font-size: 16px;
  background-color: #FFFFFF;
  height: 100%;
  width: 100%;
}
input{
  border:none;
  outline: none;
}
button{
  border:none;
  background-color: white;
}
ul {
  margin: 0;
  padding: 0;
}

li {
  margin: 0;
  padding: 0;
  list-style: none; /*去掉li左侧的点*/
}

</style>


<style lang="scss">
.slot_headerText{
  span{
    color: #ff980b;
    font-weight: bold;
  }
}
.slot_textBtn{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  button{
    height: 40px;
    text-align: center;
    border-radius: 50px;
    line-height: 40px;
    font-size: 14px !important;
    padding: 0 10px !important;
    font-weight: bold;
  }
  .btn1{
    color: #409eff;
    background: rgba(64, 158, 255, .12) !important;
  }
  .btn2{
    color: #fff;
    background: #409eff;
  }
  .btn3{
    color: #fff;
    background: #0284ff !important;
  }
}
</style>
