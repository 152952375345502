<template>
<!--  点击数字自动复制且展开-->
  <div>
    <div class="title-bar" :style="{backgroundColor: backgroundColor}">
      <div class="startBox">
        <img src="../assets/img/back.png" alt="" @click="goBack" class="img1">
        <img src="../assets/img/导航3.png" alt="" @click="goHome" class="img1">
      </div>

      <div class="centerBox">

        <div class="shang">
          <span @click="$emit('clickTitle')">{{ title }}</span>
          <span v-show="showVip" style="color: #FF0000;margin-left: 4px;">vip</span>
          <img src="../assets/img/导航4.png" alt="" @click="handleShowCardNoList" v-show="showChangeButton">
          <img src="../assets/img/导航4.png" alt="" @click="handleRefresh" v-show="!showChangeButton">
          <img v-if="xrlb===1" src="https://a.10086-wj.cn/prod-api/profile/upload/2024/08/20/5gm_20240820160744A002.png" alt="" @click="warrantyContent = true"/>
        </div>

        <!--<div class="xia">设 备</div>-->


      </div>

      <div class="endBox">
        <img src="../assets/img/导航2.png" alt="" @click="goKf" class="img1">

        <el-dropdown trigger="click" @command="handleCommand" @visible-change="handleVisible">
          <span class="el-dropdown-link">
            <img src="../assets/img/导航1.png" alt="" class="img2">
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-for="item in moreItemArr" :command="item.command" :key="item.text">
              <div style="display: flex;align-items: center">
                <img :src="item.icon" alt="" style="display: block;width: 26px;margin-right: 10px">
                <span>{{ item.text }}</span>
              </div>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>



    <van-popup v-model="changeCardNoShow" round closeable>
      <div style="padding: 20px;width: 60vw;" class="choose-card-container">
        <div style="padding-bottom: 20px;">选择卡号</div>
        <div class="item" v-for="item in cardNoList" :key="item.cardNo">
          <div>{{item.showCardNo}}</div>
          <div>
            <span v-show="showChangeButton" @click="handleJiebang">解绑</span>
            <span @click="handleChangeCardNo(item)" style="margin-left: 14px;">切换</span>
          </div>
        </div>
        <div class="add-btn" @click="handleAddNewCard">添加新卡</div>
      </div>
    </van-popup>

    <van-popup v-model="addCardNoShow" round closeable>
      <div style="padding: 20px;width: 60vw;" class="choose-card-container">
        <div style="padding-bottom: 20px;">添加新卡</div>
        <div><input type="text" class="input-card" placeholder="请输入卡号" maxlength="20" v-model="newCardNo"></div>
        <div class="add-btn" @click="handleAddCardNo">添加</div>
      </div>
    </van-popup>

    <div id="jiebang"></div>



    <van-popup v-model="warrantyContent" closeable>
      <div class="zhibao">
        <img class="topImg" src="https://a.10086-wj.cn/prod-api/profile/upload/2024/08/20/np4_20240820163329A003.png" alt=""/>
        <!--19.9-->
        <img v-if="xrlbidx===0" class="contentImg" src="https://a.10086-wj.cn/prod-api/profile/upload/2024/08/22/4e4_20240822092041A021.png" alt=""/>
        <!--9.9-->
        <img v-if="xrlbidx===1" class="contentImg" src="https://a.10086-wj.cn/prod-api/profile/upload/2024/08/22/4t7_20240822091924A020.png" alt="">
      </div>
    </van-popup>

  </div>
</template>

<script>
import {getCardInfo, jumpKefu, numAdd, numDiv, saveCardInfo} from "@/utils";
import {getInfoByCardNo, recordIdInfo,getCardNoList,jiebang} from "@/api/iot";
import {mapState} from "vuex";
import {bindingCard} from "@/api/jinmai";
import cache from "@/utils/cache";
import {showZljszfsbtc} from "@/components/ZljszfsbtcJs";

export default {
  props:{
    title: {type:String},
    backgroundColor: {type:String,default: '#FFFFFF'},
    showChangeButton: {type: Boolean, default: false},
    showVip: {type:Boolean, default: false}
  },
  data(){
    return{
      warrantyContent:false,  //终身质保内容
      // 1实名认证 2套餐订购 3余额充值 4联系客服 5充值活动 6智能检测 7收藏链接 8更多功能 9换卡
      moreItemArr:[
        // {command: 1,text: '实名认证',icon:require('../assets/img/下拉菜单5.png')},
        // {command: 2,text: '套餐订购',icon:require('../assets/img/下拉菜单7.png')},
        // {command: 3,text: '余额充值',icon:require('../assets/img/下拉菜单8.png')},
        // {command: 4,text: '联系客服',icon:require('../assets/img/下拉菜单4.png')},
        // {command: 5,text: '充值活动',icon:require('../assets/img/下拉菜单1.png')},
        // {command: 6,text: '智能检测',icon:require('../assets/img/下拉菜单9.png')},
        {command: 7,text: '收藏链接',icon:require('../assets/img/下拉菜单6.png')},
        // {command: 8,text: '换卡',icon:require('../assets/img/下拉菜单3.png')},
        {command: 9,text: '更多',icon:require('../assets/img/下拉菜单2.png')},
      ],
      newCardNo: '', // 添加的卡号
      cardInfo: {},
      changeCardNoShow: false, // 切换卡号弹窗
      addCardNoShow: false, // 添加卡号弹窗
      cardNoList: [], // 卡号列表
      xrlb: 2, // 订购过新人礼包 1=是 2=否
      xrlbidx: 1, // 新人礼包价格 0=19.9 1=9.9
    }
  },
  computed:{
    ...mapState(['cardNo'])
  },
  created() {
    // 当前的卡号放到cache中
    // 点击切换卡号时，调用getCardNoList接口获取卡号列表（从cache中获取当前的卡号）
    // 切换后emit一个事件

    let cardInfo = getCardInfo();
    this.cardInfo = cardInfo || {};
    this.getInfo();
  },
  methods:{
    // 刷新
    handleRefresh() {
      location.reload();
    },
    handleAddNewCard() {
      // this.addCardNoShow = true;
      this.$router.push({path: '/logon'});
    },
    // 解绑
    handleJiebang() {
      // {"code":200,"data":null,"msg":"success"}
      showZljszfsbtc('解绑','是否确定解绑卡？','','知道了',()=>{
        jiebang(this.$store.state.cardNo).then(res=>{
          this.$toast('解绑成功');
          const changeCardNo = res.changeCardNo;
          this.$store.commit('setCardNo','')
          this.$store.commit('setShowCardNo','')
          cache.local.remove('cardNo');
          if (changeCardNo) {
            this.handleChangeCardNo(changeCardNo);
            this.$router.push({path: '/flowCard',query: {cardNo: changeCardNo}})
          }else{
            this.$router.push({path: '/logon'});
          }
        })
      },()=>{

      },()=>{

      })

/*      this.$dialog.confirm({
        // title: '解绑',
        messageAlign: 'left',
        message: '是否确定解绑卡？',
        getContainer: '#jiebang'
      }).then(()=>{

        jiebang(this.$store.state.cardNo).then(res=>{
          this.$toast('解绑成功');
          const changeCardNo = res.changeCardNo;
          this.$store.commit('setCardNo','')
          this.$store.commit('setShowCardNo','')
          cache.local.remove('cardNo');
          if (changeCardNo) {
            this.handleChangeCardNo(changeCardNo);
            this.$router.push({path: '/flowCard',query: {cardNo: changeCardNo}})
          }else{
            this.$router.push({path: '/logon'});
          }
        })

      }).catch(()=>{

      })*/
    },
    handleShowCardNoList() {
      getCardNoList(this.cardNo).then(res=>{
        console.log("号卡列表",res)
        // this.cardNoList = res.cardNoList;
        this.cardNoList = res.cardNoList2;
        this.changeCardNoShow = true;
      })
    },
    // 切换卡号
    handleChangeCardNo(item){
      // 切换的卡号与当前选中的卡号不同时，才切换
      // if (this.cardNo !== cardNo) {
        this.$store.commit('setCardNo', item.cardNo);
        this.$store.commit('setShowCardNo', item.showCardNo);
        this.changeCardNoShow = false;
        this.$emit('changeCardNo', item.cardNo);
        this.getInfo();
      // }
    },
    // 添加新卡
    handleAddCardNo() {
      if (!this.newCardNo || !/^[0-9]*$/.test(this.newCardNo)) {
        console.log("???xxx")
        // this.$toast('请输入正确的卡号');
        showZljszfsbtc('提示','请输入正确的卡号','','知道了',()=>{
        },()=>{

        },()=>{

        })

        /*this.$dialog.alert({
          title: '提示',
          messageAlign: 'left',
          message: '请输入正确的卡号'
        }).then(()=>{})*/
        return;
      }
      console.log("添加新卡",this.newCardNo)
      const cardInfo = getCardInfo() || {};
      bindingCard(this.newCardNo,cardInfo.customId).then(res=>{
        console.log("添加新卡",res);
        if (res.code !== 200) {
          this.addCardNoShow = false;
          this.changeCardNoShow = false;
          this.$toast(res.msg);
        }else{
          this.$store.commit('setCardNo', this.newCardNo);
          // this.addCardNoShow = false;
          this.$toast('添加成功')
          this.getInfo();
        }
      })
    },
    getInfo() {
      // 本地查询卡信息
      getInfoByCardNo(this.cardNo).then(res=>{
        let {code,info,yichangIndex,xrlb,xrlbidx}=res;
        console.log("卡信息",res,code===200,code==200)
        if (code === 200) {
          this.xrlb = xrlb; // 是否订购过新人礼包 1=是 2=否
          this.xrlbidx = xrlbidx; // 新人礼包价格 0=19.9 1=9.9
          console.log('显示新人礼包图标',this.xrlb)
          info.virtualEnable = numDiv(info.virtualEnable,1024);
          const {finalEndTime} = info;
          if (finalEndTime) {
            const days = (new Date(finalEndTime).getTime()-new Date().getTime())/(60*1000*60*24)
            console.log("相差多少天",days)
            info.leftDays = Math.max(Math.floor(days),0);
          }
          saveCardInfo(info);
          this.yichangIndex = yichangIndex || 0;
        }
      })
    },

    goBack(){
      this.$router.go(-1);
    },
    goHome() {
      console.log("去首页",this.$route)
      if (this.$route.path === '/flowCard') {
        this.handleRefresh();
      }else{
        // this.$router.replace({path: '/flowCard'})
        const localEnvironment = location.href.includes('localhost')
        location.href = localEnvironment? 'http://'+location.host+'/flowCard' : 'https://a.10086-wj.cn/flowCard'
      }
    },
    goKf() {
      // this.$router.push({path:'/kefu',query: {scene_param:this.cardNo}})
      jumpKefu()
    },
    handleCommand(command) {
      console.log("点击的下拉菜单", command);
      if (command === 1) { // 实名认证
        // 先判断用户有没有实名
        recordIdInfo(this.cardInfo.owmNumber,'','').then(res=>{
          // 1=已实名 2=未实名
          const isAuthentication = res.isAuthentication || 2;
          if (isAuthentication === 1) {
            this.$toast({
              type: 'success',
              message: '已实名成功'
            })
          }else{
            this.$router.push({path: '/realName'})
          }
        })
      }else if (command === 2) { // 套餐订购
        this.$router.push({path: '/setMeal'})
      }else if (command === 3) { // 余额充值
        this.$router.push({path: '/recharge'})
      }else if (command === 4) { // 联系客服
        this.goKf();
      }else if (command === 5) { // 充值活动
        this.$router.push({path: '/huodong'})
      }else if (command === 6) { // 智能检测
        this.goKf();
      }else if (command === 7) { // 收藏链接
        // console.log("收藏链接，还没做")
        this.$router.push({path: '/contact'})
      }else if (command === 8) { // 换卡选项
        this.$router.push({path: '/huankaxuanxiang'})
      }else if (command === 9) { // 更多功能
        this.$router.push({path: '/more'})
      }
    },
    handleVisible(visible) {
      console.log("菜单显示状态",visible)
      if (visible) {
        // 本地查询卡信息
        getInfoByCardNo(this.cardNo).then(res=>{
          console.log("卡信息",res)
          // 依次检测只暴露当下第一优先级的问题
          // 0. 没有异常
          // 1. 异常提醒：手机卡芯片升级（红色）  点我换卡
          // 2. 异常提醒：手机卡未实名（红色） 点我实名
          // 3. 异常提醒：卡余额不足（红色）  点我充值
          // 4. 异常提醒：未订购套餐或套餐已到期（红色） 点我订购
          // 5. 异常提醒：流量已用完（红色） 点我订购
          const yichangIndex = res.yichangIndex || 0;
          if (yichangIndex === 2) { // 未实名
            this.moreItemArr = [
              {command: 1,text: '实名认证',icon:require('../assets/img/下拉菜单5.png')},
              {command: 2,text: '套餐订购',icon:require('../assets/img/下拉菜单7.png')},
              {command: 3,text: '余额充值',icon:require('../assets/img/下拉菜单8.png')},
              {command: 4,text: '联系客服',icon:require('../assets/img/下拉菜单4.png')},
              {command: 5,text: '充值活动',icon:require('../assets/img/下拉菜单1.png')},
              {command: 6,text: '智能检测',icon:require('../assets/img/下拉菜单9.png')},
              {command: 7,text: '收藏链接',icon:require('../assets/img/下拉菜单6.png')},
              // {command: 8,text: '换卡',icon:require('../assets/img/下拉菜单3.png')},
              {command: 9,text: '更多',icon:require('../assets/img/下拉菜单2.png')},
            ];
          }else if (yichangIndex === 1) { // 注销/换卡用户
            this.moreItemArr = [
              {command: 8,text: '换卡',icon:require('../assets/img/下拉菜单3.png')},
              {command: 3,text: '余额充值',icon:require('../assets/img/下拉菜单8.png')},
              {command: 4,text: '联系客服',icon:require('../assets/img/下拉菜单4.png')},
              {command: 5,text: '充值活动',icon:require('../assets/img/下拉菜单1.png')},
              {command: 6,text: '智能检测',icon:require('../assets/img/下拉菜单9.png')},
              {command: 7,text: '收藏链接',icon:require('../assets/img/下拉菜单6.png')},
              // {command: 1,text: '实名认证',icon:require('../assets/img/下拉菜单5.png')},
              // {command: 2,text: '套餐订购',icon:require('../assets/img/下拉菜单7.png')},
              {command: 9,text: '更多',icon:require('../assets/img/下拉菜单2.png')},
            ];
          }else if (yichangIndex === 3) { // 余额小于0
            this.moreItemArr = [
              {command: 3,text: '余额充值',icon:require('../assets/img/下拉菜单8.png')},
              {command: 2,text: '套餐订购',icon:require('../assets/img/下拉菜单7.png')},
              {command: 4,text: '联系客服',icon:require('../assets/img/下拉菜单4.png')},
              {command: 5,text: '充值活动',icon:require('../assets/img/下拉菜单1.png')},
              {command: 6,text: '智能检测',icon:require('../assets/img/下拉菜单9.png')},
              {command: 7,text: '收藏链接',icon:require('../assets/img/下拉菜单6.png')},
              // {command: 1,text: '实名认证',icon:require('../assets/img/下拉菜单5.png')},
              // {command: 8,text: '换卡',icon:require('../assets/img/下拉菜单3.png')},
              {command: 9,text: '更多',icon:require('../assets/img/下拉菜单2.png')},
            ];
          }else if (yichangIndex === 4 || yichangIndex === 0) { // 套餐问题、卡状态正常
            this.moreItemArr = [
              {command: 2,text: '套餐订购',icon:require('../assets/img/下拉菜单7.png')},
              {command: 3,text: '余额充值',icon:require('../assets/img/下拉菜单8.png')},
              {command: 4,text: '联系客服',icon:require('../assets/img/下拉菜单4.png')},
              {command: 5,text: '充值活动',icon:require('../assets/img/下拉菜单1.png')},
              {command: 6,text: '智能检测',icon:require('../assets/img/下拉菜单9.png')},
              {command: 7,text: '收藏链接',icon:require('../assets/img/下拉菜单6.png')},
              // {command: 1,text: '实名认证',icon:require('../assets/img/下拉菜单5.png')},
              // {command: 8,text: '换卡',icon:require('../assets/img/下拉菜单3.png')},
              {command: 9,text: '更多',icon:require('../assets/img/下拉菜单2.png')},
            ]
          }
        });
      }
    },
  },
}
</script>
<style scoped lang="scss">
.zhibao{
  background: #011648;
  padding-bottom: 20px;
  border-radius: 20px;
  .topImg{
    width: 330px;
  }
  .contentImg{
    width: 311px;
    margin: -90px auto 0;
    display: block;
  }
}
.add-btn{
  color: white;
  background: #2b9cff;
  text-align: center;
  line-height: 2;
  font-size: 16px;
  border-radius: 6px;
  margin-top: 20px;
  width: 100%;
}
.choose-card-container{
  background: #FFFFFF;
  .van-radio{
    margin-bottom: 6px;
  }
  .item{
    display: flex;
    justify-content: space-between;
    margin-top: 8px;
    span{
      color: #1c96f6;
    }
  }
}
.input-card{
  width: 100%;
  padding: 6px;
  margin: 0;
  outline: none;
  box-sizing: border-box;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.title-bar {
  width: 100%;
  //height: 70px;
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 8px 0 8px;
  position: fixed;
  z-index: 2000;
  //background: #FFFFFF;
  top: 0;
  left: 0;
  box-sizing: border-box;


  .startBox{
    display: flex;
    align-items: center;
    img{
      display: block;
      width: 20px;
      margin-left: 10px;
      &:not(:first-child){
        margin-left: 16px;
      }
    }
  }
  .centerBox{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -10px;
    .shang{
      display: flex;
      align-items: center;
      font-weight: 600;
      flex: 1;
      img{
        width: 16px;
        display: block;
        margin-left: 6px;
      }
    }
    .xia{
      font-size: 12px;
      color: #d8982e;
      display: block;
      margin-top: 2px;
    }
  }
  .endBox{
    display: flex;
    align-items: flex-end;
    img{
      display: block;
    }
    .img2{
      width: 18px;
      margin-left: 16px;
    }
    .img1{
      width: 22px;
    }
  }
}
</style>
