import axios from 'axios'
import store from '@/store'
import cache from './cache'
import { Toast } from 'vant';
import router from '@/router';
import {setFirstLog} from "@/utils/index";

const qs = require('qs');

axios.defaults.headers['Content-Type'] = 'application/x-www-form-urlencoded;charset=utf-8'
// 创建axios实例
const service = axios.create({
  // axios中请求配置有baseURL选项，表示请求URL公共部分
  // baseURL: process.env.VUE_APP_BASE_API, // 'http://localhost:10086/'
  // baseURL: 'http://yw.ihaoma.ltd:29000/',
  // baseURL: 'https://yinxing.ihaoma.icu/jinmai/',
  // baseURL: 'https://ldysys.ihaoma.icu/jinmai/',
  baseURL: 'https://a.10086-wj.cn/jinmai/',
  // 超时
  timeout: 60000
})

// request拦截器
service.interceptors.request.use(config => {
  const token = cache.local.get('token');
  if (token) {
    console.log(config.url,token)
    config.headers.Token = token;
  }
  // 是否需要防止数据重复提交
  // const isRepeatSubmit = (config.headers || {}).repeatSubmit === false
  const isRepeatSubmit = true
  // get请求映射params参数
  if (config.method === 'get' && config.params) {
    let url = config.url + '?' + tansParams(config.params);
    url = url.slice(0, -1);
    config.params = {};
    config.url = url;
  }
  if (!isRepeatSubmit && (config.method === 'post' || config.method === 'put')) {
    const requestObj = {
      url: config.url,
      data: typeof config.data === 'object' ? JSON.stringify(config.data) : config.data,
      time: new Date().getTime()
    }
    const sessionObj = cache.session.getJSON('sessionObj')
    if (sessionObj === undefined || sessionObj === null || sessionObj === '') {
      cache.session.setJSON('sessionObj', requestObj)
    } else {
      const s_url = sessionObj.url;                  // 请求地址
      const s_data = sessionObj.data;                // 请求数据
      const s_time = sessionObj.time;                // 请求时间
      const interval = 1000;                         // 间隔时间(ms)，小于此时间视为重复提交
      if (s_data === requestObj.data && requestObj.time - s_time < interval && s_url === requestObj.url) {
        const message = '数据正在处理，请勿重复提交';
        console.warn(`[${s_url}]: ` + message)
        return Promise.reject(new Error(message))
      } else {
        cache.session.setJSON('sessionObj', requestObj)
      }
    }
  }
  if (typeof config.data === 'object') {
    config.data = qs.stringify(Object.assign(config.data,{
      timestamp: new Date().getTime(),
      source: 'wxPublic',
      registerResource: 2,
      resourceName: '小朵智能'
    }))
  }
  return config
}, error => {
    console.log(error)
    Promise.reject(error)
})

// 响应拦截器
service.interceptors.response.use(res => {
    const data = res.data;
    const {code,msg} = data||{}
    let config = res.config;
    if (msg && msg.includes('未登录')) {
      const cardNo = store.state.cardNo;
      if (cardNo) {
        return service({
          url: 'user/base/loginByCard',
          method: 'get',
          params: {ownNum: cardNo}
        }).then(res=>{
          console.log("刷新token", res);
          if (res.code === 200) {
            const token = res.data.token;
            cache.local.set('token',token)
            config.headers.Token = token;
            return service(config);
          }else if (res.code === 1700 && res.msg === '手机号未绑定') {
            cache.local.set('tmpPci', this.cardNo);
            // this.$router.push({path: '/logon'})
            // 2023年11月27日20:44 优先级插队到2：
            // 针对登录的时候提示“手机号不存在“的用户，跳转到该页面：
            // http://yw.ihaoma.ltd/iot/index.html#/pages/login/cardLogin
            location.href='http://yw.ihaoma.ltd/iot/index.html#/pages/login/cardLogin';
          }else{
            router.push({path: '/flowCard'});
          }
        })
      }
    }else{
      return data;
    }
  },
  error => {
    // const href = location.href;
    // let {baseURL,data,headers,method,url} = error.config
    // let errInfo = JSON.stringify({baseURL,data,headers,method,url,href});
    // console.log('err' + error,errInfo)
    let { message } = error;
    if (message === "Network Error") {
      message = "后端接口连接异常";
      // 系统挂掉后直接跳转原系统
      setFirstLog("request1-2 "+JSON.stringify({errorConfig:error.config,message}))
      // 2023年12月1日08:30 yd 技术部-内部 关闭如果加载不出来就跳转first链接功能
      // location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
    } else if (message.includes("timeout")) {
      message = "系统接口请求超时";
    } else if (message.includes("status code 502")) {
      // 接口502直接跳转原系统
      setFirstLog("request2-2 "+JSON.stringify({errorConfig:error.config,message}))
      // 2023年12月1日08:30 yd 技术部-内部 关闭如果加载不出来就跳转first链接功能
      // location.href = 'http://yw.ihaoma.ltd/iot/index.html#/pages/firstRecharge/index';
    } else if (message.includes("Request failed with status code")) {
      message = "系统接口" + message.substr(message.length - 3) + "异常";
    }
    // Toast(message)
    return Promise.reject(error)
  }
)

function tansParams(params) {
  let result = ''
  params = Object.assign(params,{
    timestamp: new Date().getTime(),
    source: 'wxPublic',
    registerResource: 2,
    resourceName: '小朵智能'
  })
  for (const propName of Object.keys(params)) {
    const value = params[propName];
    var part = encodeURIComponent(propName) + "=";
    if (value !== null && value !== "" && typeof (value) !== "undefined") {
      if (typeof value === 'object') {
        for (const key of Object.keys(value)) {
          if (value[key] !== null && value[key] !== "" && typeof (value[key]) !== 'undefined') {
            let params = propName + '[' + key + ']';
            var subPart = encodeURIComponent(params) + "=";
            result += subPart + encodeURIComponent(value[key]) + "&";
          }
        }
      } else {
        result += part + encodeURIComponent(value) + "&";
      }
    }
  }
  return result
}

export default service
