import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'normalize.css/normalize.css'
import './utils/rem'
import 'vant/lib/index.css'
import './assets/css/index.scss'
import Cache from "@/utils/cache";


Cache.local.set('jsapiUrl',location.href);

// import mixins from "@/mixins";
// Vue.mixin(mixins)
import filters from "@/utils/filters";
Vue.use(filters);

import VueTouch from 'vue-touch'
VueTouch.registerCustomEvent('doubletap', {
    type: 'tap',
    taps: 2
})
Vue.use(VueTouch, {name: 'v-touch'})

import {VueJsonp} from 'vue-jsonp'
Vue.use(VueJsonp)

if (!Array.isArray) {
    Array.isArray = function(arg) {
        return Object.prototype.toString.call(arg) === '[object Array]';
    };
}

import 'element-ui/lib/theme-chalk/index.css';
import {Dropdown,DropdownItem,DropdownMenu} from "element-ui";
import NavBar2 from "@/components/NavBar2";

Vue.component('nav-bar2', NavBar2);
import Zljszfsbtc from "@/components/Zljszfsbtc";

Vue.component('zljszfsbtc', Zljszfsbtc);

Vue.use(Dropdown);
Vue.use(DropdownItem);
Vue.use(DropdownMenu);

const BrowserLogger = require('@arms/js-sdk');
const __bl = BrowserLogger.singleton(
    {
      pid:"hwyfakswm7@b136b3d85507caa",
      appType:"web",
      imgUrl:"https://arms-retcode.aliyuncs.com/r.png?",
      sendResource:true,
      enableLinkTrace:true,
      behavior:true,
      useFmp:true,
      enableSPA:true,
      enableConsole:true
    }
);

import { Checkbox,Field,Uploader,Switch,RadioGroup, Radio,Popup,Dialog,Toast,Swipe, SwipeItem,Icon,Cell,CellGroup,List,Picker } from 'vant';
import { Image as VanImage } from 'vant';

Vue.use(List);
Vue.use(Swipe);
Vue.use(SwipeItem);

Vue.use(Checkbox);
Vue.use(Field);
Vue.use(Uploader);
Vue.use(Switch);
Vue.use(Radio);
Vue.use(RadioGroup);
Vue.use(Popup);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(Icon);
Vue.use(Cell);
Vue.use(CellGroup);
Vue.use(VanImage)
Vue.use(Picker)

import VueClipboard from 'vue-clipboard2'
Vue.use(VueClipboard)

Vue.config.productionTip = false

Vue.prototype.$EventBus = new Vue()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')


import FingerprintJS from '@fingerprintjs/fingerprintjs'
import cache from "@/utils/cache";
import zljszfsbtc from "@/components/Zljszfsbtc";

const question = cache.local.get('question')
if (isNaN(question)) {
    cache.local.remove('question');
}

// 获取指纹
FingerprintJS.load().then(fp => {
    // The FingerprintJS agent is ready.
    // Get a visitor identifier when you'd like to.
    fp.get().then(result => {
        // This is the visitor identifier:
        const visitorId = result.visitorId;
        console.log("指纹",visitorId);
        cache.local.set('fp', visitorId);
    });
});
